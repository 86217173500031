import * as React from 'react';
import { withSubtheme } from '../../../../StarberryComponentsMui';

import { getUser } from "../../../../services/store/utils"
import { useUpdateProfilePasswordMutation } from '../../../../redux/services/profile'
import { collectResponseMessage } from '../../../../redux/utils'

import ComponentLayout from '../../ComponentLayout';
import formFields from "../../forms/Forms/changePass"
import PasswordResetForm from "../../forms/"
import { trackProfile } from "../../helper/eventTracking"

import defaults from './defaults';
import { Box, Grid } from '@mui/material';
import SectionTitle from '../../pages/my-property/common/sectionTitle';
import { DocumentBox } from '../../StyleComp';
import CircularProgressComponent from '../../progress/CircularProgress';

const UserChangePasswordComponent = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        profileChangePasswordProps,
        // ...rest
    } = props;

    const ref = React.useRef(null)
    const [updateUser, result, error, isError, isLoading] = useUpdateProfilePasswordMutation()
    const messageDialog = collectResponseMessage(result, error, isError, "Password updated!");

    const handleSubmit = (event) => {
        event.preventDefault();
        if (ref.current.checkValidity() !== false) {
            const formsdata = ref.current;
            const values = {
                id: getUser().uid,
            }
            Object.keys(formsdata).map(key => {
                if (formsdata[key].value)
                    return (
                        values[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
                    )
                else
                    return false;
            })
            if (values?.password && values?.confirmPassword) {
                if (values.password === values.confirmPassword) {

                    updateUser(values).then((res) => {
                        trackProfile({
                            eventLabel: 'Update Password'
                        })
                    })

                } else {
                    // services.updateMessage({message:"Passwords do not match", error:true, fieldValues:values});
                }
            }
        } else {
            // services.updateMessage({message:"Please fill required fields", error:true});
        }
    };

    const showLoading = isLoading || result?.status == 'pending';

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SectionTitle
                    {...props}
                    title={`Change your password`}
                    tabsDescription={'change_password'}
                />
                <DocumentBox>
                    <Box className="content">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {showLoading && (
                                    <CircularProgressComponent />  
                                )}
                                {!showLoading && (
                                    <PasswordResetForm
                                        ref={ref}
                                        formFields={formFields}
                                        handleSubmit={handleSubmit}
                                        messageDialog={messageDialog}
                                        submitLabel={'Update Password'}
                                    />
                                )}
                                </Grid>
                        </Grid>
                    </Box>
                </DocumentBox>
            </Grid>

        </Grid>
    )
}, 'profileMyAccountChangePassword', defaults)

export default UserChangePasswordComponent;
