import * as React from "react"
import { withSubtheme } from "../../../../StarberryComponentsMui";
import { get } from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import { useGetProfileByIdQuery, useUpdateProfileMutation } from '../../../../redux/services/profile'
import { updateProfileState } from '../../../../redux/reducers/profileSlice'
import { collectResponseMessage } from '../../../../redux/utils'
import ComponentLayout from "../../ComponentLayout"
import { getUser } from "../../../../services/store/utils"
import CircularProgress from "../../progress/CircularProgress";
import formFields from "../../forms/Forms/notificationPreferences"
import ProfileForm from "../../forms/"
import { trackProfile } from "../../helper/eventTracking"
import {
    MYACCOUNT_LIGHT
} from "../../../../constants/urls";
import defaults from './defaults';
import { Box, Grid } from "@mui/material";
import SectionTitle from "../../pages/my-property/common/sectionTitle";
import { DocumentBox } from "../../StyleComp";
import CircularProgressComponent from "../../progress/CircularProgress";

const UserNotificationComponent = withSubtheme(props => {
    const {
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps
    } = props

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { data, error, isError, isLoading } = useGetProfileByIdQuery(state?.profile?.user?.uid);
    const [updateUser, result] = useUpdateProfileMutation()
    const messageDialog = collectResponseMessage(result, error, isError, "Notification preferences updated!");
    
    const ref = React.useRef(null)

    const formStateType = {...data, ...(state?.profile?.user || {})} || {}

    // filter fields
    const filteredFormFields = formFields.filter((x) => {
        if (MYACCOUNT_LIGHT)
            return get(x, 'light', true)
        else
            return get(x, 'enterprise', true)
    })

    const handleSubmit = event => {
        event.preventDefault()
        if (ref.current.checkValidity() !== false) {
            const formsdata = ref.current;
            const values = {
                id: getUser().uid,
            }
            Object.keys(formsdata).map(key => {
                switch (formsdata[key].type) {
                    case "checkbox":
                        return values[formsdata[key].name] = formsdata[key].checked
                    default:
                        return (values[formsdata[key].name] = formsdata[key].value)
                }
            })
            updateUser(values).then((res) => {
                dispatch(updateProfileState(res.data));
                trackProfile({
                    eventLabel: 'Notification preferences'
                })
            })
        } else {
            // @todo We could display the global from error message. But anyhow field validations are done field itself
        }
    }

    // if (isLoading || result?.status == 'pending') {
    //     return <CircularProgress />
    // }

    const showLoading = isLoading || result?.status == 'pending'

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SectionTitle
                    {...props}
                    title={`Notification Preferences`}
                    tabsDescription={'notification_preferences'}
                />
                <DocumentBox>
                    <Box className="content">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {showLoading && (
                                    <CircularProgressComponent />  
                                )}
                                {!showLoading && (
                                    <ProfileForm
                                        formFields={filteredFormFields}
                                        handleSubmit={handleSubmit}
                                        formStateType={formStateType}
                                        messageDialog={messageDialog}
                                        ref={ref}
                                        formState={{loading:showLoading}}
                                        submitLabel={'Save'}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DocumentBox>
            </Grid>
        </Grid>
    )
}, "profileMyAccountNotificationPreferences", defaults)

export default UserNotificationComponent
