import React, { useState } from 'react';
import { useParams } from '@reach/router';
import { withSubtheme } from '../../../StarberryComponentsMui';
import { doProfileUpdate, getCurrentTab, getTabLists } from '../helper';
import { MYACCOUNT_LIGHT } from "../../../constants/urls";

// @todo Hngh. We need to stop confusing components-mui's Grid, myaccount's Grid and MUI's Grid.
import { Grid, Container, CardContent, Typography, Card, Box } from '@mui/material';

import GlobalLayout from "../Layout";

import Tabs from '../tabs';
import ProfileCard from '../user/profileCard';
import Profile from '../user/profile';
import ProofOfFunds from '../user/proofofFunds'
import DeleteAccount from '../user/deleteAccount'
import ChangePass from '../user/password';
import NotificationPreference from '../user/notificationPreference';
import PropertyPreferences from '../user/propertyPreference/dialog';
import PropertyPreferenceIndex from '../user/propertyPreference';
import ValuationModule from '../simpleModule/valuation';
import { ProfileStoreProvider, useAuthState } from "../../../services";

import { useSelector, useDispatch } from "react-redux";
import { updateReduxPropertyPreference } from '../../../redux/reducers/propPreferenceSlice';
import { ListActionsBoxWrap } from '../StyleComp';
import StarberryIcons from '../icons';
import { LinkItem } from '../helper/myaccountLink';
import LogoutDialog from '../user/logoutDialog';
import { margin, styled } from '@mui/system';
import { useHasPersonaQuery } from '../../../redux/services/profile';

const ProfileBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2)
    }
}));
  
const ProfileFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={8}>
                <Profile />
            </Grid>
        </Grid>
    )
})

const ChangePassFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={8}>
                <ChangePass />
            </Grid>
        </Grid>
    )
})

const NotificationFormSidebar = React.memo(({ showValuationWidget }) => {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={8}>
                <NotificationPreference />
            </Grid>
        </Grid>
    )
})

const PropertyPreferencesFormSidebar = React.memo(() => {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={12}>
                <PropertyPreferenceIndex />
            </Grid>
        </Grid>
    )
});

const ProofOfFundsFormSidebar = React.memo(() => {
    const { state, services } = useAuthState();
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg={12}>
                <ProofOfFunds state={state} services={services} />
            </Grid>
        </Grid>
    )
});

const defaultTabItems = {
    'personal-details': {},
    'property-preferences': {},
    'notification-preference': {},
    'change-password': {},
    'delete-account': {},
    'logout':{}
};

const defaultTabItem = 'personal-details';

const TabComp = (props) => {
    // tabItems Can be Array or Objects
    // const _tabItems = ['personal-details', 'property-preference', 'notification-preference', 'change-password', 'logout'];

    const tabItems = props?.tab?.items || defaultTabItems;
    const defaultItem = props?.tab?.default || defaultTabItem;

    const currentTab = getCurrentTab(defaultItem, tabItems);
    const [value] = React.useState(currentTab);

    const dispatch = useDispatch();
    const user = useSelector(state => state.profile);
    const preference = useSelector(state => state.propertyPreference);

    let availableTabs = {
        "personal-details": {
            "id": "personal-details",
            "name": "Personal Details",
            "content": <ProfileFormSidebar showValuationWidget={props?.showValuationWidget} />,
        }
    }

    if (!MYACCOUNT_LIGHT) {
        availableTabs["property-preference"] =  {
            "id": "property-preference",
            "name": "Property Preference",
            "content": <PropertyPreferencesFormSidebar />,
        }
    }

    availableTabs["notification-preferences"] =  {
        "id": "notification-preferences",
        "name": "Notification Preferences",
        "content": <NotificationFormSidebar showValuationWidget={props?.showValuationWidget} />,
    }

    availableTabs["change-password"] =  {
        "id": "change-password",
        "name": "Change Password",
        "content": <ChangePassFormSidebar showValuationWidget={props?.showValuationWidget} />
    }

    availableTabs["proof-of-funds"] =  {
        "id": "proof-of-funds",
        "name": "Proof of Funds",
        "content": <ProofOfFundsFormSidebar showValuationWidget={props?.showValuationWidget} />
    }
    availableTabs["delete-account"] =  {
        "id": "delete-account",
        "name": "Delete Account",
        "content": <DeleteAccount />
    }

    const data = React.useMemo(() => {
        return getTabLists(availableTabs, tabItems);
    }, [tabItems]);

    const { services, state } = useAuthState();

    const getData = async () => {
        try {
            const data = await services.hasPersona();
            // update has persona here...
            dispatch(updateReduxPropertyPreference(!data));
            
        } catch (e) {
            console.log("Error => ", e.response)
            if (e?.response?.status === 401) {
                services.logout()
            }
        }
    }

    React.useEffect(/*async*/() => {
        services.updateState({pageTitle:`Profile`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
        // dispatch(updateReduxPropertyPreference(false));

        if (!MYACCOUNT_LIGHT)
            getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ref: https://github.com/facebook/react/issues/15156 Option 3
    return React.useMemo(() => {
        return (
            <>
                <Tabs
                    data={data}
                    manageValue={value}
                    tabItems={tabItems}
                    addUrl={true}
                    altClassName={`profile-tabs`}
                />
                {(!!preference.showModal) && (
                    <PropertyPreferences
                        conversationalFormEnabled={props.conversationalFormEnabled}
                    />
                )}
            </>
        )
    }, [/*pendingItemsCountCall, */preference.showModal]) // eslint-disable-line react-hooks/exhaustive-deps
}

const ProfileLandingCards = (props) => {


    const { data:hasPersona, error, isError, isLoading } = useHasPersonaQuery();
    
    const { services, state } = useAuthState();
    const [open, setOpen] = React.useState(false)
    const handleClick = (event, id) => {
        if (id == 'SignOut') {
            event.preventDefault()
            setOpen(true)
        }
    }

    const data = [
        {
            title: "Personal Details",
            icon: "profileEditIcon",
            desc: "Tell us more about you.",
            link: '/profile/personal-details'
        },
        {
            title: "Property Preferences",
            icon: "preferenceIcon",
            desc: "Line of text for this tile.",
            link: '/profile/property-preferences'
        },
        {
            title: "Notification Preferences",
            icon: "notificationIcon",
            desc: "Line of text for this tile.",
            link: '/profile/notification-preferences'
        },
        {
            title: "Change Password",
            icon: "passwordIcon",
            desc: "Line of text for this tile.",
            link: '/profile/change-password'
        },
        {
            id: 'SignOut',
            title: "Sign Out",
            icon: "customLogoutIcon",
            desc: "Line of text for this tile.",
            link: 'personal-details',
        },
        {
            title: "Delete Account",
            icon: "deleteIcon",
            desc: "Line of text for this tile.",
            link: '/profile/delete-account'
        }
    ]
    return (
        <>
            {hasPersona === false && (
                <PropertyPreferences
                    conversationalFormEnabled={props.conversationalFormEnabled}
                />
            )}
            <ListActionsBoxWrap>
            <Grid container spacing={2} className='profilecards'>
            {data.map((row, index) => (
                <Grid item xs={12} md={4} lg={4}>
                    <LinkItem to={row.link} onClick={(e) => { handleClick (e, row?.id)}}>
                        <Card className="action-card-item d-block grid-card" key={index}>
                            <Grid container spacing={2} alignItems="baseline">
                                <Grid item className="action-icon"><Typography>.</Typography>
                                <StarberryIcons iconName={row.icon} />
                                </Grid>
                                <Grid item xs>
                                <CardContent>
                                    <Typography variant="h2">{row.title}</Typography>
                                    <Typography className="info-text" variant="body2" color="textSecondary">
                                    {row.desc}
                                    </Typography>
                                </CardContent>
                                </Grid>
                            </Grid>
                        </Card>
                    </LinkItem>
                </Grid>
            ))}
            </Grid>

            </ListActionsBoxWrap>
            <LogoutDialog
                open={open}
                handleConfirm={() => services.logout()}
                handleCancel={setOpen}
            />
        </>

    )
}

const ProfileComponents = (props) => {

    const urlParams = useParams()

    if (urlParams?.tab) {
        switch (urlParams?.tab) {
            case 'personal-details':
                return <ProfileFormSidebar {...props} />
            case 'property-preferences':
                return <PropertyPreferencesFormSidebar {...props} />
            case 'notification-preferences':
                return <NotificationFormSidebar {...props} />
            case 'change-password':
                return <ChangePassFormSidebar {...props} />
            case 'delete-account':
                return <DeleteAccount {...props} />
                    
            default:
                return <ProfileLandingCards {...props} />
        }
    }

    return <ProfileLandingCards {...props} />

}
const MyAccountInboxInner = withSubtheme((props) => {

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    <ProfileCard />
                </Grid> */}
                <Grid item xs={12}>
                    <ProfileBox>
                        <ProfileStoreProvider>
                            <ProfileComponents {...props} />
                            {/* <TabComp
                                tab={props?.tab}
                                showValuationWidget={props?.showValuationWidget}
                                conversationalFormEnabled={
                                    props.conversationalFormEnabled
                                }
                            /> */}
                        </ProfileStoreProvider>
                    </ProfileBox>
                </Grid>
            </Grid>
        </Container>
    );
}, 'profilePage')

const MyAccountInbox = () => {
    return (
        <GlobalLayout>
            <MyAccountInboxInner />
        </GlobalLayout>
    )
}

export default React.memo(MyAccountInbox);
