export default {
    "props": {
        "forceRegistration": false,
        "dialogTitle": "We need a little more info...",
        "dialogDesc": "This would be a line of text that explains what they are going to get by filling out this form.",
    },
    "sx": {

    },
}
