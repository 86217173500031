/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/

import { useTheme, withSubtheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_PROFILE,
    MYACCOUNT_PROFILE_TAB
} from "../../core/constants/urls";

import PrivateRoute from "../../core/components/MyAccount/hoc/PrivateRoute"
import Profile from "../../core/components/MyAccount/pages/profile"
import PageHeaderHelmet from "../../core/components/pageHeader";

import _myAccount from "../../core/sample/myAccount.yaml"

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_PROFILE}
                    component={Profile}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_PROFILE_TAB}
                    component={Profile}
                    myAccount={myAccount}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
