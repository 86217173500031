export default {
    props: {
        formTerms: `<ul><li>A valid current Proof of Funds is required. </li>

                <li>If you are mortgage approved please upload your Approval In Principle in date. </li>

                <li>If you are a cash buyer please upload an up to date image/screenshot of your cash funds. Name on the account must be the same as the buyers (your) name.</li>

                <li>If the bid is a combination of gift letter and cash or AIP, please upload the gift letter and either AIP or Cash buyer proof as above.</li>


                <li>Our agent will review the documentation and may ask for clarification. <b><i>(Uploaded Doc/PDF/Image must not be greater that 4mb)</i></></li>
            </ul>`,
        btnLabel: "ADD PROOF OF FUNDS",
        showPlusBtn: true,
        btnProps: {
            // "color": "secondary",
            variant: "contained",
            sx: theme => ({
                width: "100%",
                marginBottom: theme.spacing(2),
                [theme.breakpoints.up("md")]: {
                    maxWidth: "300px",
                    width: "100%",
                },
            }),
        },
    },
}
