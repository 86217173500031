import * as React from "react"
import { withSubtheme } from "../../../../StarberryComponentsMui"
import ComponentLayout from "../../ComponentLayout"
import { getUser } from "../../../../services/store/utils"
import profileFormFields from "../../forms/Forms/profile"
import ProfileForm from "../../forms/"
import { collectFormValues, validateFormFields } from "../../forms/utils";
import { MYACCOUNT_LIGHT } from "../../../../constants/urls";
import CircularProgress from "../../progress/CircularProgress";
import { trackProfile } from "../../helper/eventTracking"
import { useGetProfileByIdQuery, useUpdateProfileMutation } from '../../../../redux/services/profile'
import { collectResponseMessage } from '../../../../redux/utils'
import { updateProfileState } from '../../../../redux/reducers/profileSlice'
import defaults from "./defaults";

import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from "@mui/material"
import { DocumentBox } from "../../StyleComp"
import SectionTitle from "../../pages/my-property/common/sectionTitle"

const UserProfileComponent = withSubtheme(props => {
    const {
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        className
    } = props

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { data, error, isError, isLoading } = useGetProfileByIdQuery(state?.profile?.user?.uid);
    const [updateUser, result] = useUpdateProfileMutation()

    let customFields = [...profileFormFields];

    const [formFields, setFormFields ] = React.useState(customFields);
    const messageDialog = collectResponseMessage(result, error, isError, "Profile updated!");
    const ref = React.useRef(null)
    const formStateType = {...data, ...(state?.profile?.user || {})} || {}

    const handleSubmit = event => {
        event.preventDefault()
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            window.scrollTo(0, 0)
        }
        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);

        if (ref.current.checkValidity() !== false && validateFormFields(ref, customFields, true) === false) {
            const params = {
                id: getUser().uid,
            }
            let values = collectFormValues(ref, params)
            if (!MYACCOUNT_LIGHT) {
                values = {...values, contact_id:state?.user?.contact_id}
            }
            updateUser(values).then((res) => {
                dispatch(updateProfileState(res.data));
                trackProfile({
                    eventLabel: 'Profile'
                })
            })
        } else {
            // services.updateMessage({message:"Please fill required fields", error:true});
            // @todo We could display the global from error message. But anyhow field validations are done field itself
        }
    }

    const showLoading = state.loading || isLoading || result?.status == 'pending';

    // if (state.loading || isLoading || result?.status == 'pending') {
    //     return <CircularProgress />
    // }
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SectionTitle
                    {...props}
                    title={`Personal Details`}
                    tabsDescription={'personal_details'}
                />
                <DocumentBox>
                    <Box className="content">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {showLoading && (
                                    <CircularProgress />  
                                )}
                                {!showLoading && (
                                    <ProfileForm
                                        formFields={formFields}
                                        // services={services}
                                        handleSubmit={handleSubmit}
                                        formStateType={formStateType}
                                        messageDialog={messageDialog}
                                        ref={ref}
                                        submitLabel={'Save'}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DocumentBox>
            </Grid>

        </Grid>

    )
}, "profileMyAccountForm", defaults)

export default UserProfileComponent
